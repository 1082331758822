export const passwordRequirements = {
    methods: {
      passwordRequirements (password) {
        return [
          { content: 'interface.password-minimum-length', isMet: password.length >= 8 },
          { content: 'interface.password-require-lowercase', isMet: this.hasLowerCase(password) },
          { content: 'interface.password-require-digit', isMet: /\d/.test(password) },
          { content: 'interface.password-require-uppercase', isMet: this.hasUpperCase(password) }
        ]
      },
      repeatPasswordRequirements (password, confirmPassword) {
        return [{ content: 'interface.password-confirm-equal', isMet: password === confirmPassword }]
      },
      arePasswordsRequirementsMet (password, confirmPassword) {
        return this.passwordRequirements(password).every(x => x.isMet) &&
        this.repeatPasswordRequirements(password, confirmPassword).every(x => x.isMet)
      },
      hasLowerCase (str) {
        return str.toUpperCase() !== str
      },
      hasUpperCase (str) {
        return str.toLowerCase() !== str
      }
    }
  }
  