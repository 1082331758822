<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button @click="$router.push({name: 'MyProfile'})"></ion-back-button>
        </ion-buttons>
        <ion-title v-text="__('interface.change-password')"></ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-item>
        <ion-label position="floating">{{ __('interface.old-password') }}</ion-label>
        <ion-input :type="showPassword.old ? 'text' : 'password'"  v-model="form.oldPassword" required autocomplete="current-password"/>
        <ion-button fill="clear" slot="end" class="ion-align-self-end" icon-only style="margin-left:0;" @click="toggleShow('old')">
          <ion-icon :icon="showPassword.old ? eyeOff : eye"></ion-icon>
        </ion-button>
      </ion-item>
      <ion-item>
        <ion-label position="floating">{{ __('interface.new-password') }}</ion-label>
        <ion-input :type="showPassword.new ? 'text' : 'password'"  v-model="form.newPassword" required autocomplete="new-password"/>
        <ion-button fill="clear" slot="end" class="ion-align-self-end" icon-only style="margin-left:0;" @click="toggleShow('new')">
          <ion-icon :icon="showPassword.new ? eyeOff : eye"></ion-icon>
        </ion-button>
      </ion-item>
      <requirement-list v-if="form.newPassword.length > 0" :requirements="passwordRequirements(form.newPassword)"/>
      <ion-item>
        <ion-label position="floating">{{ __('interface.confirm-new-password') }}</ion-label>
        <ion-input :type="showPassword.confirm ? 'text' : 'password'"  v-model="form.confirmNewPassword" required autocomplete="new-password"/>
        <ion-button fill="clear" slot="end" class="ion-align-self-end" icon-only style="margin-left:0;" @click="toggleShow('confirm')">
          <ion-icon :icon="showPassword.confirm ? eyeOff : eye"></ion-icon>
        </ion-button>
      </ion-item>
      <requirement-list v-if="form.confirmNewPassword.length > 0 || form.newPassword.length > 0"
                        :requirements="repeatPasswordRequirements(form.newPassword, form.confirmNewPassword)"/>
      <ion-button expand="full" class="ion-margin-vertical" @click="actionSave"
                  :disabled="!arePasswordsRequirementsMet(form.newPassword, form.confirmNewPassword)">
        {{ __('interface.change-password') }}
      </ion-button>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar, loadingController, toastController
} from "@ionic/vue";
import RequirementList from "../../components/RequirementList";
import {Auth} from "@aws-amplify/auth";
import AuthService from "../AuthService";
import {passwordRequirements} from "../../utils/passwordRequirements";
import {IonIcon} from "@ionic/vue";
import {eye, eyeOff} from 'ionicons/icons';

export default {
  name: "ChangePassword",
  mixins: [passwordRequirements, AuthService],
  components: {IonContent, IonHeader, IonTitle, IonToolbar, IonButtons, IonBackButton, RequirementList, IonPage, IonIcon},
  props: {
    close: {type: Function}
  },
  data() {
    return {
      form: {
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: ''
      },
      showPassword: {
        old: false,
        new: false,
        confirm: false
      }
    }
  },
  setup() {
    return {
      eye,
      eyeOff
    }
  },
  methods: {
    async actionSave() {
      const loading = await loadingController.create({
        message: this.__('interface.loading'),
      });
      await loading.present();

      Auth.currentAuthenticatedUser().then(user => {
        return Auth.changePassword(user, this.form.oldPassword, this.form.newPassword)
      }).then(async () => {
        this.form = {
          oldPassword: '',
          newPassword: '',
          confirmNewPassword: ''
        }
        await loading.dismiss();
        const toast = await toastController.create({
          message: this.__('message.password-change-successfully'),
          duration: 2000
        })
        await toast.present();
        this.$tracking.event({
            eventName: 'change-password',
            category: 'profile'
          });
      }).catch(async () => {
        await loading.dismiss();
        const toast = await toastController.create({
          message: this.__('message.error-password-change'),
          duration: 2000
        })
        await toast.present();
      }).finally(() => {
        loading.dismiss();
      })
    },
    toggleShow (input) {
      this.showPassword[input] = !this.showPassword[input]
    }
  },
}
</script>