<template>
    <div class="requirements ion-padding">
        <div v-for="(requirement, key) in requirements" :key="key" class="requirement">
            <span>
                <ion-icon v-if="requirement.isMet" :icon="checkmarkOutline" class="icon-success"/>
                <ion-icon v-else :icon="closeOutline" class="icon-error"/>
            </span>
            <div>{{ __(requirement.content) }}</div>
        </div>
    </div>
</template>

<script>
import {IonIcon} from '@ionic/vue';
import {
    closeOutline,
    checkmarkOutline
} from 'ionicons/icons';

export default {
    components: {IonIcon},
    name: 'TsRequirementList',
    props: {
        requirements: Array
    },        
    setup() {
        return {
            closeOutline,
            checkmarkOutline,
        }
    }
}

</script>

<style lang="scss">
    .requirements {
        animation-name: enter;
        animation-duration: 300ms;
        animation-fill-mode: forwards;

        @keyframes enter {
            from {
                opacity: 0;
                transform: translateY(0);
            }

            to {
                opacity: 1;
                transform: translateY(3px);
            }
        }
    }

    .requirement {
        display: flex;
        align-items: center;
         
        &:not(:last-child) {
            margin-bottom: 0.5rem;
        }

        > span {
            display: flex;
            align-items: center;
        }

        > div {
            margin-left: 8px;
        }
    }

    .icon-success {
        color: var(--ion-color-success);
    }

    .icon-error {
        color: var(--ion-color-danger);
    }
</style>
